.am-navbar{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    color: #fff;
  }
  
  .grid-css{
    margin-top: 45px;
    font-size: 14px;
    padding-top: 8px;
    padding-left: 8px;
    background: #ffffff;
  }
  .loginButton {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
  }
