.am-navbar{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;

  }

  .grid-css1{
    /* margin-top: 45px; */
    font-size: 16px;
    /* padding-top: 8px; */
    padding-left: 8px;

  }
  .grid-css-child{
    /* margin-top: 45px; */
    font-size: 16px;
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    background: #ffffff;
    font-weight: 700;
  }
  .yylb{
    margin-left: 8px;
    width: calc(100% - 16px);
  }
  .yybl{
    margin-top:12px;
  }
  .not-square-grid .am-grid-icon {
    width: 40px;
    height: 60px;
  }
