.App {
  text-align: center;
  background-color: white;
  overflow: hidden;
}





.app-name {
  font-size: 20px;
  font-weight: bold;
}

.login {
  margin-top: 10px;
}

.loginButton {
  width: 80%;
  margin: 0 auto;
}
.aclass{
  text-align: center;
  color: #ccc;
  padding-top: 15px;
}
.link{
  margin-top:10px;
}
